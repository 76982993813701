// Migrated
<template lang="pug">
.charter-page-layout
  LayoutWrapper
    Header
    CharterMobileMenu
    ResponsiveImage.d-inline.banner.h-100.w-100.min-h-px-200.min-h-px-md-600(
      v-if="!isHotel"
      position="relative"
      :max-height="650"
      :aspect-ratio="false"
      :align="DEFAULT_IMAGE_ALIGN"
      :skeleton="true"
      :clip="false"
      :image="bannerImage"
      :type="bannerType"
      alt="Hero image"
    )
    ResponsiveHotelImage.d-inline.banner.h-100.w-100.min-h-px-200.min-h-px-md-600(
      v-else
      position="relative"
      :image="bannerImage"
      :type="bannerType"
      alt="Hero image"
    )
    template(#solresorContent)
      div(
        ref="contentRef"
        :key="title"
      )
        TripNavTabs(
          animated
          @on-tab-change="updateActiveTab"
        )
        slot
    template(#footer)
      NuxtLazyHydrate(:when-visible="{ rootMargin: '50px' }")
        LazyFooter
</template>

<script setup>
import { SOLRESOR_FALLBACK_HERO_ID } from '@/constants/images'

const { getLocaleMessages: localeMessages } = useLocaleStore()

const DEFAULT_IMAGE_ALIGN = '50% 40%'
const { t } = useI18n()
const rootStore = useRootStore()
const charterDestinationStore = useCharterDestinationStore()
const contentRef = ref()
// const { loading } = storeToRefs(charterDestinationStore)
const title = computed(() => charterDestinationStore.charterDestinationTitle)
const route = useRoute()
const isHotel = route.name === 'hotel-id'

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,

  link: () => [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: t('favicon')
    }
  ],

  script:[{
    innerHTML:() => localeMessages.organizationData,
    type: 'application/ld+json'
  }],

  meta: [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand')
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => t('siteTitleBrand')
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => t('siteTitleBrand')
    }
  ]
})

const {
  pageBannerType: bannerType,
  pageBannerBackground: bannerBackground,

} = storeToRefs(rootStore)

const bannerImage = computed(() => (
  bannerBackground.value?.image?.url ||
  bannerBackground.value?.image?.src ||
  bannerBackground.value?.picture_cloudinary ||
  SOLRESOR_FALLBACK_HERO_ID
))

const updateActiveTab = (newActiveTab) => {
  charterDestinationStore.SET_CHARTER_DESTINATION_TAB(newActiveTab)
}

</script>

<style lang="scss">
.charter-page-layout {
  .banner {
    max-height: 650px;
  }
  .layout-container,
  .banner-layout.container-content {
    max-width: $container-width !important;

    @media (max-width: $container-width) {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
}
</style>
